import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import './Countdown.css';
import Live from './Live';
import Main from './Main';
// import Rsvp from './rsvp/Rsvp';


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1><Link to="/">Laura &amp;<br></br>&nbsp;&nbsp;Aaron</Link></h1>
          {/* <nav>
            <ol>
              <li><a href="/#update">Updates</a></li>
              <li><a href="/#location">Location</a></li>
              <li><a href="/#accommodations">Accommodations</a></li>
              <li><a href="/#faq"><abbr title="Frequently Asked Questions">FAQ</abbr></a></li>
              <li><Link to="/rsvp">RSVP</Link></li>
              <li><a href="/#contact">Contact</a></li>
            </ol>
          </nav> */}
        </header>
        <Switch>
          <Route path="/live">
            <Live />
          </Route>
          {/* <Route path="/rsvp">
            <Rsvp />
          </Route> */}
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <footer className="App-footer">
          Hello from Toronto &mdash; &copy; 2020 - 2021 Laura and Aaron
        </footer>
      </div>
    </Router>
  );
}

export default App;
