import React from "react"
// import { CountdownTimer } from './Countdown';
// import { ReactComponent as Map } from './images/map.svg';
// import { ReactComponent as CeremonyIcon } from './images/ceremony_icon.svg';
// import { ReactComponent as ReceptionIcon } from './images/reception_icon.svg';

const Main: React.FC = () => {
    return (
      <div>
        <section className="App-container">
            <section className="App-image App-mast"></section>
        </section>
        <section className="App-section" id="update">
            <h1>Dear friends and family</h1>
            <p>We have just received confirmation from the venue that our reception planned for September 4, 2021 cannot proceed given ongoing restrictions in the context of the COVID-19 pandemic.</p>
            <p>While we would have loved to celebrate with all of you, we feel this is the right decision - having an indoor reception of more than 100 people at this point in time would not have been comfortable for us and some of our guests.</p>
            <p>If you have booked travel or accommodations, please ensure that these are cancelled before their refund date.</p>
            <p>COVID has taken so much from so many people, and we consider ourselves very fortunate. We are grateful to be married even if our wedding last year wasn’t exactly as we had originally planned it to be.</p>
            <p>Thanks to all of you for your support and patience through this whole process, we truly appreciate it.</p>
            <p>If there's anything you need from us, please don't hesitate to let us know via email at <a href="mailto:hello@lauraandaaron.ca?subject=Reception Questions">hello@lauraandaaron.ca</a></p>
            <p>♥ Laura and Aaron</p>
        </section>
        {/* <section className="App-dateLocation">
            <h2>9.4.2021 &bull; Jordan Station, ON</h2>
            <p>Cocktails, dinner, and dancing</p>
        </section>
        <CountdownTimer></CountdownTimer>
        <section className="App-section" id="location">
            <h1>Location</h1>
            <Map className="App-map"></Map>
            <div className="App-section-innerFlex">
            <div>
                <h2>Ceremony</h2>
                <p className="App-smalltext">October 11, 2020</p>
                <h3><CeremonyIcon className="App-svgIcon"></CeremonyIcon> <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/Lsgtymhi5ot6fhnv9">Cave Spring Vineyard</a></h3>
                <p>4043 Cave Spring Rd.<br></br>Beamsville, ON<br></br>L0R 1B1</p>
            </div>
            <div>
                <h2>Reception</h2>
                <p className="App-smalltext">September 4, 2021</p>
                <h3><ReceptionIcon className="App-svgIcon"></ReceptionIcon> <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/NtmJs1fmpkLtGwY76">Inn on the Twenty</a></h3>
                <p>3836 Main St.<br></br>Jordan Station, ON<br></br>L0R 1S0</p>
            </div>
            </div>
        </section>
        <section className="App-section" id="accommodations">
            <h1>Accommodations</h1>
            <div className="App-section-innerFlex">
            <div>
                <h3><span className="App-circleIcon">1</span> <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/RGVCYze4vVSzPu9Y9">Inn on the Twenty</a></h3>
                <p className="italic">Across from reception &mdash; $$$</p>
                <p>Reservations can be made in our block online through <a target="_blank" rel="noopener noreferrer" href="https://www.vintage-hotels.com/">Vintage Hotels</a> and inputting <span className="italic">3345733</span> in the "Group ID" box when selecting "Book Now" from the menu, or contact <a href="tel:+1-888-669-5566">1-888-669-5566</a>, requesting the "Reservation Line", and mentioning the <span className="italic">Kinlin/Toth Wedding</span>.</p>
                <p>The cut-off date for reservations is Sunday, July 4, 2021 at 11:59 PM.</p>
            </div>
            <div>
                <h3><span className="App-circleIcon">2</span> <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/z7dyBxfRwVyNBVNb6">Jordan House</a></h3>
                <p className="italic">~5 minute walk from reception &mdash; $$</p>
                <p>Reservations can be made using the same method as Inn on the Twenty, selecting <span className="italic">Jordan House</span> from "Book Now" on the <a target="_blank" rel="noopener noreferrer" href="https://www.vintage-hotels.com/">Vintage Hotels</a> website, or calling the number.</p>
                <p>The cut-off date is also Sunday, July 4, 2021 at 11:59 PM.</p>
            </div>
            <div>
                <h3><span className="App-circleIcon">3</span> <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/RTyDY7nv6ug6XauR9">Hampton Inn</a></h3>
                <p className="italic">~10 minute drive from reception &mdash; $</p>
                <p>Reservations can be made by calling <a href="tel:+1-905-934-5400">905-934-5400</a> and mentioning the <span className="italic">Kinlin &amp; Toth Wedding</span>. A shuttle to be arranged, more details to follow.</p>
                <p>The cut-off date for reservations under our block is Thursday, July 22, 2021.</p>
            </div>
            </div>
        </section>
        <section className="App-section" id="faq">
            <h1>Frequently Asked Questions</h1>
            <dl>
            <dt>Can I bring my kids?</dt>
            <dd>We love your little ones, but due to the size and nature of the venue we unfortunately cannot accommodate children. Thanks for understanding!</dd>

            <dt>I have extra time on the weekend, what's there to do in Jordan?</dt>
            <dd>Ball's Falls is a conservation area nearby with some short hikes to scenic waterfalls. Of course, there are lots of wineries close by that are open for tastings, as well as <a target="_blank" rel="noopener noreferrer" href="https://benchbrewing.com/">Bench Brewing Co.</a> for beer connaisseurs. Jordan Village (where Inn on the Twenty resides) has some unique boutique shops, and once you've shopped till you drop, you can grab a latte and homemade pastry at <a target="_blank" rel="noopener noreferrer" href="https://delaterre.ca/">De La Terre</a>.</dd>
            
            <dt>What should I wear?</dt>
            <dd>We suggest cocktail/semi-formal attire. The reception is being held indoors, but features outdoor spaces like a licensed patio if you need to cool down after dancing up a storm.</dd>
            </dl>
        </section> */}
        <section className="App-section" id="contact">
            <h1>Contact</h1>
            <p><a href="mailto:hello@lauraandaaron.ca?subject=Hello">hello@lauraandaaron.ca</a></p>
        </section>
        <section className="App-container">
            <section className="App-image App-footerImage"></section>
        </section>
      </div>  
    );
}

export default Main;