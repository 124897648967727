import "./Live.css";
import React from "react";
import ReactPlayer from "react-player/file";

const Live: React.FC = () => {
    return (
        <section className="App-section no-header">
            <ReactPlayer
                playing
                controls
                className="Live-video"
                width="100%"
                height="auto"
                light="/VideoPreview.jpg"
                url="https://stream.mux.com/69Y2rJoBs3BGUzoESRbbPhZJYlxj8Hs02f00DLmaDrF3k.m3u8"
            />
            <p>Having trouble? Ensure you're using the latest Safari, Chrome, or Firefox browser on your desktop, or Safari or Chrome on your phone or tablet.</p>
            <p>Still having issues? <button onClick={() => window.location.reload()}>Reload</button> this page.</p>
            <p>If any issues persist, don't worry&mdash;we'll post the video here afterward.</p>
        </section>
    );
};

export default Live;